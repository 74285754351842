@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/Releway/Raleway-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/Releway/Raleway-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/Releway/Raleway-ExtraBold.ttf");
  /* IE9 Compat Modes */
  src: local("Raleway"), url("../fonts/Releway/Raleway-ExtraBold.ttf") format("truetype");
  /* Safari, Android, iOS */
}
