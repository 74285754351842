/* Inter */

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 100;
    src: url("../fonts/Inter/static/Inter-Thin.ttf"); /* IE9 Compat Modes */
    src: local("Inter"), url("../fonts/Inter/static/Inter-Thin.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 200;
    src: url("../fonts/Inter/static/Inter-ExtraLight.ttf"); /* IE9 Compat Modes */
    src: local("Inter"),
      url("../fonts/Inter/static/Inter-ExtraLight.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/Inter/static/Inter-Light.ttf"); /* IE9 Compat Modes */
    src: local("Inter"), url("../fonts/Inter/static/Inter-Light.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Inter/static/Inter-Regular.ttf"); /* IE9 Compat Modes */
    src: local("Inter"),
      url("../fonts/Inter/static/Inter-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/Inter/static/Inter-Medium.ttf"); /* IE9 Compat Modes */
    src: local("Inter"), url("../fonts/Inter/static/Inter-Medium.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/Inter/static/Inter-SemiBold.ttf"); /* IE9 Compat Modes */
    src: local("Inter"),
      url("../fonts/Inter/static/Inter-SemiBold.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/Inter/static/Inter-Bold.ttf"); /* IE9 Compat Modes */
    src: local("Inter"), url("../fonts/Inter/static/Inter-Bold.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    src: url("../fonts/Inter/static/Inter-ExtraBold.ttf"); /* IE9 Compat Modes */
    src: local("Inter"),
      url("../fonts/Inter/static/Inter-ExtraBold.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/Inter/static/Inter-Black.ttf"); /* IE9 Compat Modes */
    src: local("Inter"), url("../fonts/Inter/static/Inter-Black.ttf") format("truetype"); /* Safari, Android, iOS */
  }